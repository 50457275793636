import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Box, Text } from 'grommet'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import moment from 'moment'

export const EventPostTemplate = ({
  content,
  contentComponent,
  title,
  start,
  end,
  entryFee,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <p>Start: {moment(start).format("dddd, MMMM Do YYYY, h:mm A")}</p>
            <p>End: {moment(end).format("dddd, MMMM Do YYYY, h:mm A")}</p>
            <p>Entry Fee: {entryFee}</p>
            <Box
              direction="row"
              align="center"
	      background="accent-4"
              pad="medium"
              elevation="small"
              justify="between"
            >
              <Text
                margin={{ left: 'small' }}
                color="calendar-modal-text"
                a11yTitle="Event Description"
              >
                <PostContent content={content} />
              </Text>
            </Box>
          </div>
        </div>
      </div>
    </section>
  )
}

EventPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
  entryFee: PropTypes.string,
  helmet: PropTypes.object,
}

const EventPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <EventPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet
            titleTemplate="%s | Event"
          >
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
        title={post.frontmatter.title}
        start={post.frontmatter.start}
        end={post.frontmatter.end}
        entryFee={post.frontmatter.entryFee}
      />
    </Layout>
  )
}

EventPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default EventPost

export const pageQuery = graphql`
  query EventPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        start
        end
        entryFee
      }
    }
  }
`
